import React from 'react'
import { shape, string } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../components/LocalizationContext'
import MetaData from '../../../components/common/MetaData'
import ProductGrid from '../../../components/shop/ProductGrid'
import ShopLayout from '../../../components/shop/ShopLayout'
import MobileCartBlock from '../../../components/shop/MobileCartBlock'

import styles from '../../../styles/books.module.css'

const LivresPage = ({ location }) => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "douces-soupes/00-couverture.png" }) {
                childImageSharp {
                    fixed(width: 180) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <LocalizationContext.Provider value={{ locale: LOCALES.FR, localizedURL: `/en/books/` }}>
            <MetaData location={location} />
            <ShopLayout pathname={location.pathname}>
                <h1 className={styles.title}>Mes livres et ebooks</h1>
                <p>En parallèle de mes articles de blog et de mon métier de styliste/photographe culinaire, j&apos;écris aussi des livres que vous pouvez retrouver sur cette page.</p>
                <MobileCartBlock />
                <ProductGrid products={[
                    {
                        name: `Douces Soupes`,
                        caption: `Un beau livre qui regroupe 25 recettes de soupes pour l’automne et l’hiver`,
                        path: `/fr/livres/douces-soupes/`,
                        image: data.file,
                    },
                ]} />
            </ShopLayout>
        </LocalizationContext.Provider>
    )
}

LivresPage.propTypes = {
    location: shape({
        pathname: string.isRequired,
        search: string.isRequired,
    }).isRequired,
}

export default LivresPage
