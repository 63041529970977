import React, { useContext } from 'react'

import { LayoutContext } from '../../blog/Layout/LayoutContext'
import CartWidget from '../ShopLayout/CartWidget'

export const MobileCartBlock = () => {
    const { isSmallScreen } = useContext(LayoutContext)

    if (!isSmallScreen) {
        return null
    }

    return (
        <CartWidget />
    )
}
