import React /*, { useContext }*/ from 'react'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './ProductCard.module.css'

export const ProductCard = (props) => {
    const { item } = props

    return (
        <Link className={styles.container} to={item.path}>
            <Img className={styles.img} fixed={item.image.childImageSharp.fixed} />
            <h3 className={styles.title}>{item.name}</h3>
            <p className={styles.caption}>{item.caption}</p>
        </Link>
    )
}

ProductCard.propTypes = {
    item: propTypes.shape({
        name: propTypes.string.isRequired,
        caption: propTypes.string,
        path: propTypes.string.isRequired,
        image: propTypes.shape({
            childImageSharp: propTypes.shape({
                fixed: propTypes.shape({
                    src: propTypes.string,
                }),
            }),
        }),
    }).isRequired,
}
